import { trackLinkInPiwikAndGA } from './CombinedGAPiwik';
import { querySelectorAllWithShadowed } from 'Shared/utils/shadow-wrapper';

const trackableLinks = querySelectorAllWithShadowed(
   '[data-analytics-event-category]'
) as unknown as Array<HTMLElement>;

trackableLinks.forEach(link => {
   const category = link.dataset.analyticsEventCategory;
   const eventName = link.dataset.analyticsEventName;
   trackLinkInPiwikAndGA(link, {
      // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      eventCategory: category,
      eventAction: `${category} - Click - ${eventName}`,
   });
});
