/**
 * Returns a standards compliant promise that resolves to the same thing
 * as the passed promise. This can be used to convert a somewhat non-standard
 * promise i.e. from jQuery.ajax() into one that uses our Promise prototype.
 */
Promise.from = function (promise) {
   return new Promise(resolve => {
      resolve(promise);
   });
};

/**
 * Ensures unhandled promise rejections are thrown immediately
 */
Promise.prototype.done = function (onFulfilled, onRejected) {
   let self = arguments.length ? Reflect.apply(this.then, this, arguments) : this;
   return self.then(null, err => {
      setTimeout(() => {
         throw err;
      }, 0);
   });
};
