import author_autocompleter_item from 'hbs/author_autocompleter_item.hbs';
import blurb from 'hbs/blurb.hbs';
import suggest_global_toggle from 'hbs/suggest_global_toggle.hbs';
import tag_autocompleter_item from 'hbs/tag_autocompleter_item.hbs';
import loading_indicator from 'hbs/loading_indicator.hbs';
import loading_indicator_message from 'hbs/loading_indicator_message.hbs';
import uploader from 'hbs/FrameModules/MediaLibrary/uploader.hbs';

function getter(json) {
   return function (key) {
      if (!Object.prototype.hasOwnProperty.call(json, key)) {
         let errorStr = 'Property ' + key + ' does not exist!';
         throw errorStr;
      }
      return json[key];
   };
}

window.hbsTemplates = getter({
   AUTHOR_AUTOCOMPLETER_ITEM_HBS: Template.get(author_autocompleter_item),
   BLURB_HBS: Template.get(blurb),
   SUGGEST_GLOBAL_TOGGLE_HBS: Template.get(suggest_global_toggle),
   TAG_AUTOCOMPLETER_ITEM_HBS: Template.get(tag_autocompleter_item),
   LOADING_INDICATOR_HBS: Template.get(loading_indicator),
   LOADING_INDICATOR_MESSAGE_HBS: Template.get(loading_indicator_message),
   MEDIA_LIBRARY_UPLOADER_HBS: Template.get(uploader),
});
