/*
Copyright 2019 Paul Irish

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.

*/
export class YoutubePlayer {
   static elementClass = '.youtube-player';

   static init() {
      const videoElements = Array.from(document.querySelectorAll(YoutubePlayer.elementClass));
      if (videoElements.length) {
         videoElements.forEach(el => new YoutubePlayer(el));
      }
   }

   private video: Element;
   private videoId: string;
   private src: URL;

   constructor(videoEl: any) {
      this.video = videoEl;

      const playButton = document.createElement('button');
      playButton.className = 'play-btn';
      playButton.addEventListener('click', () => this.addVideo());
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      this.video.parentNode.append(playButton);

      this.videoId = videoEl.getAttribute('videoid');
      this.src = new URL(videoEl.getAttribute('src'));

      this.video.addEventListener('mouseover', () => (playButton.style.filter = 'grayscale(0%)'));
      this.video.addEventListener(
         'mouseleave',
         () => (playButton.style.filter = 'grayscale(100%)')
      );
   }

   private addVideo() {
      const videoEl = document.createElement('iframe');
      videoEl.allow = 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture';
      videoEl.allowFullscreen = true;
      videoEl.setAttribute('loading', 'lazy');
      const params = this.src.searchParams;
      params.append('wmode', 'opaque');
      params.append('autoplay', '1');
      videoEl.src = `https://www.youtube-nocookie.com/embed/${this.videoId}?${params.toString()}`;

      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      this.video.parentNode.append(videoEl);
   }
}
