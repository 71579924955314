import { _js } from '@ifixit/localize';
import { FormLibrary } from 'Shared/utils';
import tippy from 'tippy.js';

/**
 * Class: FormManager
 *
 * Wraps an HTML form element, providing methods to add error handlers to the
 * form and to display errors as soon as the user enters them. Automatically
 * wraps the form with a submit handler which validates the form, cancelling
 * the submit if there are errors. Also provides convenience methods to
 * manually validate or submit the form.
 */
// eslint-disable-next-line no-var
export var FormManager = (window.FormManager = new Class({
   Implements: [Options, Events],

   options: {
      ajaxLoader: true,
      jumpToErrors: true,
      fixedPosition: false,

      /**
       * The purpose of this is to allow the user to determine if any of the
       * form fields have been modified from their original value.
       *
       * Any field with this class name will be monitered to see if a change
       * from the original value has happened. Calling isModified will return
       * this value.
       */
      watchClass: 'watchModified',
   },

   modified: false,

   // PUBLIC //////////////////////////////////////////////////////////////////

   /**
    * Constructor: FormManager
    *    Creates a new FormManager.
    *
    * Arguments:
    *    form - The form to manage (id or node).
    */
   initialize: function (form, options) {
      this.setOptions(options);
      this.form = $(form);
      this.submitHandlers = [];
      this.pendingRequests = 0;
      this.validating = false;
      this.invalidField = false;

      // Set up form elements to check for errors on the fly.
      FormLibrary.getFields(this.form).each(this.initializeElement, this);

      this.initializeWatch();

      // Create the status box that tells the user what's wrong with a field.
      this.statusMessageEl = this.buildStatusMessage();

      this.form.addEvent('submit', this.submitted.bind(this));
      this.form.store('FormManager:formManager', this);
   },

   /**
    * Method: addSubmitHandler
    *    Adds a function to the chain of functions called before the managed
    *    form is submitted. This method must be used instead of a normal
    *    addEvent because each handler can cancel the submit and the execution
    *    of any subsequent handlers by returning false.
    *
    * Arguments:
    *    fn - A function with no arguments, which gets executed before the form
    *         is submitted and which may cancel the submit by returning false.
    *
    * Example:
    *    (begin code)
    *    register.addSubmitHandler(function() {
    *       // never let the form be submitted
    *       return false;
    *    })
    *    (end)
    */
   addSubmitHandler: function (fn) {
      this.submitHandlers.push(fn);
   },

   /**
    * Method: prependSubmitHandler
    *    Exactly the same as addSubmitHandler, but adds event to the front
    *    of the queue instead of the end.
    *
    * Arguments:
    *    fn - A function with no arguments, which gets executed before the form
    *         is submitted and which may cancel the submit by returning false.
    *
    * Example:
    *    (begin code)
    *    register.prependSubmitHandler(function() {
    *       // never let the form be submitted
    *       return false;
    *    })
    *    (end)
    */
   prependSubmitHandler: function (fn) {
      this.submitHandlers.unshift(fn);
   },

   /**
    * Method: setValidator
    *    Sets the validator function for a form element (each form element may
    *    have only one validator). The validator is a function of one argument
    *    (the element the validator is attached to). The validator should
    *    validate the value of the element and report errors back to the
    *    manager using the <error> or <requiredError> methods.
    *
    * Arguments:
    *    el - The form field (id or node) to attach the validator to.
    *    fn - The validator function.
    *
    * Example:
    *    (begin code)
    *    register.setValidator('zipcode', function(el) {
    *       if (!zipcode.match(/\d{5}(-\d{4})?/))
    *          register.error(el, 'Invalid zipcode');
    *    });
    *    (end)
    *
    * See also:
    *    <error>, <requiredError>
    */
   setValidator: function (el, fn) {
      el = $(el);
      el && el.store('FormManager:validator', fn);
   },

   /**
    * Method: setDefaultText
    *    Sets default text for text inputs, removing the text when the field
    *    gains focus and putting it back if the user has left the field empty
    *    on blur.
    *
    *    Can also be set using the `data-default-text` attribute.
    *
    * Arguments:
    *    el   - The text input that gets the default text.
    *    text - The default text.
    *
    * Example:
    *    :register.setDefaultText(el, 'Example: John Doe');
    *
    * Example2:
    *    <input type="text" ... data-default-text="Example: John Doe'>
    */
   setDefaultText: function (el, text) {
      el.store('FormManager:defaultText', text);
      if (el.get('value').trim() === '') {
         el.addClass('defaultText');
         el.set('value', text);
      }
   },

   /**
    * Method: error
    *    Registers an error on a form field. The error will not be displayed
    *    immediately, but when the user returns to the form field or the field
    *    is jumped to explicitly by <showError> or <showErrors>.
    *
    * Arguments:
    *    el       - The form field on which to register the error.
    *    message  - The error message to show when the user returns to the form
    *               field.
    *    required - Optional, a boolean value that is true if the error is a
    *               "required error" and false otherwise.
    *
    * Example:
    *    :register.error(el, 'This is not a valid US phone number.');
    *
    * See also:
    *    <requiredError>, <showError>, <showErrors>
    */
   error: function (el, message, required) {
      this.setErrorState($(el), message, required);
   },

   /**
    * Method: requiredError
    *    Returns the default error message for a required field.
    *
    * Example:
    *    :return register.requiredError();
    *
    * See also:
    *    <error>, <required>
    */
   requiredError: function (msg) {
      if (!msg) {
         msg = _js('This field is required.');
      }
      return new FormManager.RequiredError(msg);
   },

   /**
    * Method: customError
    *    Returns an instance of FormManager.CustomError to act as a flag to the
    *    validateField function, indicating that the validator found an error,
    *    but that it will take care of display. The form manager is still
    *    responsible, though, for not letting the form be submitted while the
    *    error is present.
    */
   customError: function () {
      return new FormManager.CustomError();
   },

   /**
    * Method: required
    *    Takes one or more form fields and adds a "required" validator to each
    *    one.  The "required" validator checks to see if the field has been
    *    modified from its default state ('' for text fields and a
    *    selectedIndex of 0 for drop-downs), and registers an error if it
    *    hasn't.
    *
    * Arguments:
    *    Any number of form fields (ids or nodes).
    *
    * Example:
    *    :register.required('firstName', 'lastName');
    *
    * See also:
    *    <requiredError>
    */
   required: function () {
      const requiredFields = Array.convert(arguments);

      requiredFields.forEach(el => {
         this.setValidator(el, this.checkEmpty.bind(this));
      });
   },

   /**
    * Method: showError
    *    Gives a form field focus, then displays the error message associated
    *    with it. This action will be done automatically if there are errors
    *    when the form is validated, and should only be called explicitly when
    *    manually validating a form through an AJAX response. If the
    *    'jumpToErrors' option is set (default true), then the window will also
    *    scroll to the element.
    *
    * Arguments:
    *    el      - The form field to jump to (id or node).
    *    message - The error message to set on the element.
    *
    * Example:
    *    (begin code)
    *    // from an AJAX call
    *    if (!customerExists) {
    *       register.showError(loginField, 'No such user.');
    *    }
    *    (end)
    *
    * See also:
    *    <error>
    */
   showError: function (el, message) {
      el = $(el);
      this.error(el, message);
      el.activate();

      if (this.options.jumpToErrors && !el.isOnScreen()) {
         el.jumpTo();
      }

      this.showStatusMessage(el);
   },

   /**
    * Method: showErrors
    *    Like <showError>, but allows registration of multiple errors in one
    *    call. The first error is jumped to as in showError.
    *
    * Arguments:
    *    Any number of objects, each with two properties:
    *    element - The form field (id or node) on which to register an error.
    *    message - The error message to set on the element.
    *
    * Example:
    *    (begin code)
    *    register.showErrors(
    *       {element: loginField, message: 'Invalid login.'},
    *       {element: passwordField, message: 'Invalid password.'}
    *    );
    *    (end)
    *
    * See also:
    *    <showError>
    */
   showErrors: function (errors) {
      errors.each(error => {
         this.error(error.element, error.message);
      });

      let el = $(errors[0].element);
      el.activate();
      if (this.options.jumpToErrors && !el.isOnScreen()) {
         el.jumpTo();
      }
      this.showStatusMessage(el);
   },

   /**
    * Method: focus
    *    Focuses the first form field in the managed form.
    *
    * Example:
    *    :register.focus();
    */
   focus: function () {
      FormLibrary.focusFirst(this.form);
   },

   /**
    * Method: setValue
    *    Sets a new value for a textual form field, clears any existing errors,
    *    then re-validates it.
    *
    * Arguments:
    *    el - The form field (id or node) to set a new value for.
    *    newValue - The new value for the field.
    *
    * Example:
    *    :// try setting the city automatically
    *    :register.setValue(cityField, cityGuess);
    */
   setValue: function (el, newValue) {
      el = $(el);
      el.value = newValue;

      this.clear(el);
      this.validateField(el);
   },

   /**
    * Method: validate
    *    Runs through each field in the managed form and calls its validator.
    *    If there are any errors, then the first one is jumped to.
    *
    * Example:
    *    :register.validate();
    */
   validate: function (onComplete) {
      // One validation at a time.
      if (this.validating) {
         return false;
      }

      this.validating = true;
      this.validationContinuation = onComplete;
      FormLibrary.getFields(this.form).each(this.validateField, this);

      if (!this.pendingRequests > 0) {
         this.completeValidation();
      }
   },

   completeValidation: function () {
      let fields = FormLibrary.getFields(this.form);

      this.validating = false;

      // Use a traditional for-loop so we can break early.
      for (const field of Array.from(fields)) {
         if (field.retrieve('FormManager:hasError')) {
            field.activate();
            if (this.options.jumpToErrors && !field.isOnScreen()) {
               field.jumpTo();
            }
            if (!field.retrieve('FormManager:customError')) {
               this.showStatusMessage(field);
            }
            this.validationContinuation = null;
            return false;
         }
      }

      if (typeOf(this.validationContinuation) == 'function') {
         this.validationContinuation();
         this.validationContinuation = null;
      }
   },

   /**
    * Method: checkIfValid
    *    Similar to validate above except for instead of displaying errors
    *    to the user it will pass the boolean valid to onComplete callback.
    *
    * Example:
    *    formMan.checkIfValid(function(valid) {
    *       submit.toggleClass('disabled', valid);
    *    });
    */
   checkIfValid: function (onComplete) {
      if (this.validating) {
         return false;
      }

      this.validating = true;
      this.invalidField = false;

      FormLibrary.getFields(this.form).each(this.checkField, this);

      this.validationContinuation = onComplete;

      if (this.pendingRequests === 0) {
         this.completeCheck();
      }
   },

   completeCheck: function () {
      this.validating = false;

      if (typeof this.validationContinuation == 'function') {
         this.validationContinuation(!this.invalidField);
         this.validationContinuation = null;
      }
   },

   /**
    * Method: submit
    *    Submits the managed form, which will cause it to be validated and the
    *    submit handlers to be run (if the form validates).
    *
    * Example:
    *    :register.submit();
    *
    * See also:
    *    <validate>
    */
   submit: function () {
      return this.submitted();
   },

   /**
    * Method: submitNow
    *    Submits the managed form using the form's submit method, which
    *    bypasses validation and submit handlers, guaranteeing that the form
    *    will be submitted without any further processing. This method is
    *    useful if you want to submit a form in two different ways, one tied
    *    to a "submit" input and the other tied to some other button that does
    *    something to the form before submitting (e.g. a preview button).
    *
    * Example:
    *    (begin code)
    *    $('previewButton').addEvent('click', function() {
    *       manager.validate(function() {
    *          new Element('input', {
    *             'type':'hidden',
    *             'name':'preview'
    *          }).inject(manager.form);
    *          manager.submitNow();
    *       })
    *    });
    *    (end)
    *
    * See also:
    *    <submit>, <validate>
    */
   submitNow: function () {
      this.form.submit();
   },

   // EVENT HANDLERS //////////////////////////////////////////////////////////

   /**
    * Called when a form field receives focus; shows an error message if the
    * field has an error.
    */
   focused: function (el) {
      el.store('FormManager:hasFocus', true);

      // Highlight elements that have errors and come before this element.
      // Clear the highlight on all other errors.
      this.highlightErrorsRelativeTo(el);

      // If the element has default text, clear it.
      if (el.hasClass('defaultText')) {
         el.set('value', '');
         el.removeClass('defaultText');
      }

      if (el.retrieve('FormManager:hasError') && !el.retrieve('FormManager:customError')) {
         this.showStatusMessage(el);
      }
   },

   /**
    * Called when a form field loses focus; clears the error status of the
    * field and then validates it.
    */
   blurred: function (el) {
      el.store('FormManager:hasFocus', false);
      this.clearIfError(el);

      // If the element has default text, and has been left empty, then restore
      // the default text and note that the element is in its default state.
      // It's important to do this before running the validator, as it may
      // depend on the "defaultText" state.
      let defaultText = el.retrieve('FormManager:defaultText');
      if (defaultText && el.get('value').trim() == '') {
         el.addClass('defaultText');
         el.set('value', defaultText);
      }

      this.validateField(el);
   },

   /**
    * Called when the managed form is submitted; cancels the submit if the form
    * doesn't validate or any of the submit handlers return false.
    */
   submitted: function (ev) {
      let submitted = false;
      // Cancel the submit immediately and perform validation; if validation
      // succeeds, then we do a final submit.
      if (ev) {
         ev.stop();
      }

      this.removeDefaultValues();
      // The form must validate.
      this.validate(function () {
         // All submit handlers must return true.
         let okay = this.submitHandlers.every(fn => fn(this.form));

         if (okay) {
            // This submit can't be stopped.
            this.form.submit();
            submitted = true;
         }
      });
      return submitted;
   },

   /**
    * Returns the value of a form field, or the checked status for a checkbox
    * or radio button.
    */
   getValue: function (field) {
      return ['checkbox', 'radio'].contains(field.get('type'))
         ? field.get('checked')
         : field.get('value');
   },

   /**
    * Returns all form field values as an object.
    */
   getValues: function () {
      let obj = {};
      let getValue = this.getValue;
      FormLibrary.getFields(this.form).each(el => {
         obj[el.get('name')] = getValue(el);
      });
      return obj;
   },

   // PRIVATE /////////////////////////////////////////////////////////////////

   /**
    * Builds the statusMessage, which displays the error on an element.
    */
   buildStatusMessage: function () {
      return new Element('p').addClass('formManagerStatus').hide();
   },

   /**
    * Initializes a form field, setting default values and adding event
    * listeners.
    *
    * If the element has a noFocusBlur class, focus and blur events are not
    * added to that element. For elements with this class, you have to trigger
    * the `blurred` and `focused` events on your own.
    */
   initializeElement: function (el) {
      // Each element tracks its own state.
      el.store('FormManager:hasError', false);
      el.store('FormManager:hasRequiredError', false);
      el.store('FormManager:errorMessage', '');
      el.store('FormManager:validator', () => {});
      el.store('FormManager:hasFocus', false);
      el.store('FormManager:pendingResponse', false);
      el.store('FormManager:customError', false);

      let defaultText = el.get('data-default-text');
      if (defaultText) {
         this.setDefaultText(el, defaultText);
      }

      // Every form element gets the chance to do something on focus and blur.
      if (!el.hasClass('noFocusBlur')) {
         el.addEvents({
            focus: this.focused.bind(this, el),
            blur: this.blurred.bind(this, el),
         });
      }

      // Install any extra events the element needs to support.
      this.installEvents(el);
   },

   /**
    * Installs events other than the basic focus/blur on the input element. In
    * this class, we install keypress or click events (depending on the
    * element) to clear the field once the user starts interacting with it. A
    * subclass might choose to have no extra event handlers at all.
    */
   installEvents: function (el) {
      // Typing in text fields and clicking on everything else gets the error
      // message out of the user's face.
      if (['password', 'text', 'textarea'].contains(el.get('type'))) {
         el.addEvent('keydown', this.clearIfError.bind(this, el));
      } else {
         el.addEvent('click', this.clearIfError.bind(this, el));
      }
   },

   initializeWatch: function (options) {
      this.watchFields = this.form.getElements('.' + this.options.watchClass);

      // Store each original form value with its input and add the event
      // that checks for a new value.
      this.watchFields.each(field => {
         field.store('FormManager:originalValue', this.getValue(field));
         const checkModified = function () {
            let originalValue = field.retrieve('FormManager:originalValue');
            let modified = this.getValue(field) != originalValue;
            field.store('FormManager:modified', modified);
            this.updateModifiedStatus();
         };
         field.addEvents({
            input: checkModified.bind(this),
            change: checkModified.bind(this),
         });
      });
   },

   /**
    * Stores the current value as the "original value" so that the field will
    * not register as modified again until it is altered by a user.
    *
    * This should be primarily used after an AJAX-like save of the field
    * contents.
    */
   resetOriginalValue: function (field) {
      field.store('FormManager:originalValue', this.getValue(field));
      field.store('FormManager:modified', false);
      this.updateModifiedStatus();
   },

   /**
    * Resets all the watched form fields to be "unmodified".
    *
    * Generally to be used after an AJAX request to reset the "isModified"
    * state for the form manager.
    */
   resetAllOriginalValues: function () {
      this.watchFields.each(this.resetOriginalValue.bind(this));
   },

   /**
    * Retrieves the validator function for an element and runs it, passing the
    * element to the validator.
    */
   validateField: function (el) {
      // If this field is pending a response, skip it.
      if (el.retrieve('FormManager:pendingResponse')) {
         return;
      }

      // Clear existing errors.
      this.clearIfError(el);

      // Retrieve the validator function and run it.
      let result = null;
      let validator = el.retrieve('FormManager:validator');
      if (validator) {
         result = validator(el);
      }

      // If the validator returns a string, it's an error.
      if (typeOf(result) == 'string') {
         this.error(el, result);
      } else if (result instanceof FormManager.RequiredError) {
         // Pass the optional third parameter to error, telling it that this is
         // a "required error".
         this.error(el, result.message, true);
      } else if (result instanceof FormManager.AjaxIOValidator) {
         // El is waiting for an ajax response to check the validity.
         el.store('FormManager:pendingResponse', true);
         // Give the element a loading icon if the option is enabled.
         if (this.options.ajaxLoader) {
            this.markLoading(el);
         }
         // Keep track of the number of pending requests.
         this.pendingRequests++;
         // When the request is complete, apply the same logic as above to the
         // final response (a string is an error).
         result.addEvent('onComplete', ajaxResult => {
            if (typeOf(ajaxResult) == 'string') {
               this.error(el, ajaxResult);
               // Show the highlight or status message right away for an AJAX
               // validation error.
               if (el.retrieve('hasFocus')) {
                  this.showStatusMessage(el);
               } else {
                  this.setHighlight(el);
               }
            }
            // We're no longer waiting for a response.
            el.store('FormManager:pendingResponse', false);
            // Remove the loading icon if the option is enabled.
            if (this.options.ajaxLoader) {
               this.unmarkLoading(el);
            }
            // That's one less request to worry about.
            this.pendingRequests--;
            // If pendingRequests reaches 0 and we're waiting to complete a
            // validation of the full form, finish up.
            if (!this.pendingRequests && this.validating) {
               this.completeValidation();
            }
         });
      } else if (result instanceof FormManager.CustomError) {
         el.store('FormManager:hasError', true);
         el.store('FormManager:customError', true);
      }
   },

   /**
    * Same as validateField above except for it will switch the flag
    * this.invalidField to true on any errors rather than display them
    * to the user.
    */
   checkField: function (el) {
      let result = null;
      let validator = el.retrieve('FormManager:validator');

      if (validator) {
         result = validator(el);
      }

      if (
         typeof result == 'string' ||
         result instanceof FormManager.RequiredError ||
         result instanceof FormManager.CustomError
      ) {
         this.invalidField = true;
      } else if (result instanceof FormManager.AjaxIOValidator) {
         el.store('FormManager:pendingResponse', true);

         if (this.options.ajaxLoader) {
            this.markLoading(el);
         }

         this.pendingRequests++;

         result.addEvent('onComplete', function (ajaxResult) {
            if (typeof ajaxResult == 'string') {
               this.invalidField = true;
            }
            el.store('FormManager:pendingResponse', false);

            if (this.options.ajaxLoader) {
               this.unmarkLoading(el);
            }
            this.pendingRequests--;

            if (!this.pendingRequests && this.validating) {
               this.completeCheck();
            }
         });
      }
   },

   /**
    * Checks a field to see if it's empty or in a default state and returns a
    * "required error" if it is.
    */
   checkEmpty: function (el) {
      let text = ['text', 'textarea', 'password', 'email'].contains(el.type);
      let dropdown = el.type == 'select-one';
      if (
         (text && (el.value.trim() === '' || el.hasClass('defaultText'))) ||
         (dropdown && el.selectedIndex === 0)
      ) {
         return this.requiredError();
      } else if (el.type == 'checkbox' && !el.checked) {
         return this.requiredError();
      }
   },

   /**
    * Highlights errors that come before `el` and clears any "required field"
    * errors at or after `el`.
    */
   highlightErrorsRelativeTo: function (el) {
      let showError = true;
      FormLibrary.getEditableFields(this.form).each(function (field) {
         if (field == el) {
            showError = false;
            this.clearHighlight(field);
         }
         if (field.retrieve('FormManager:hasError')) {
            if (showError) {
               this.setHighlight(field);
            } else {
               this.clearHighlight(field);
               // Clear the state for all but the current field, for which we
               // want to show the error.
               if (field != el) {
                  this.clearErrorState(field);
               }
            }
         }
      }, this);
   },

   /**
    * Displays the error associated with a form field.
    */
   showStatusMessage: function (el) {
      let msg = el.retrieve('FormManager:errorMessage');

      this.statusMessageEl.set({
         html: msg,
      });
      this.statusMessageEl.inject(el, 'after').show();

      if (this.options.fixedPosition) {
         this.statusMessageEl.pin();
      }
   },

   /**
    * Hides the status message.
    */
   hideStatusMessage: function () {
      // Check to see if the message is in the DOM and displayed before trying
      // to unpin it.
      if (this.statusMessageEl.parentNode && this.statusMessageEl.getStyle('display') != 'none') {
         this.statusMessageEl.unpin();
      }
      this.statusMessageEl.hide().dispose();
   },

   /**
    * Styles the field to indicate that it has an error.
    */
   setHighlight: function (el) {
      el.setStyle('backgroundColor', '#efd0d0');
   },

   /**
    * Clears a field's error styling.
    */
   clearHighlight: function (el) {
      el.setStyle('backgroundColor', '');
   },

   /**
    * Sets the error state for the element, storing a flag for the error,
    * whether it is a "required error", and the associated error message.
    */
   setErrorState: function (el, message, required) {
      el.store('FormManager:hasError', true);
      el.store('FormManager:hasRequiredError', required);
      el.store('FormManager:errorMessage', message);
   },

   /**
    * Clears a field's internal error tracking state.
    */
   clearErrorState: function (el) {
      el.store('FormManager:hasError', false);
      el.store('FormManager:hasRequiredError', false);
      el.store('FormManager:errorMessage', '');
      el.store('FormManager:customError', false);
   },

   /**
    * Clears a field's error status if it has one.
    */
   clearIfError: function (el) {
      if (el.retrieve('FormManager:hasError')) {
         this.clear(el);
      }
   },

   clearAllErrors: function () {
      this.invalidField = false;
      FormLibrary.getFields(this.form).each(el => {
         this.clearIfError(el);
      });
   },

   /**
    * Unconditionally clears a field's error status, removing the error
    * message, any styling on the field, and the stored fields hasError and
    * errorMessage.
    */
   clear: function (el) {
      if (!el.retrieve('FormManager:customError')) {
         this.hideStatusMessage(el);
         this.clearHighlight(el);
      }
      this.clearErrorState(el);
   },

   /**
    * Removes all the values filled into a form and sets it back to a "clear"
    * state.
    *
    * This will remove any warnings or errors on the form and will reset the
    * "modified" state to assume the form started with no values.
    */
   removeAllFormValues: function () {
      FormLibrary.getFields(this.form).each(el => {
         // Don't clear out dropdowns, set them to their "selected" value.
         //
         // We don't have default states for dropdowns so we can't "clear"
         // them. Maybe we set them to the first value? Pretty dependent on the
         // use case.
         if (el.hasClass('js-dropdown-input')) {
            el.getParent('.dropdown-wrapper').setValue(el.value);
         } else {
            el.value = '';
         }
      });

      this.resetAllOriginalValues();
      this.clearAllErrors();
   },

   /**
    * Installs an AJAX loading icon next to the field.
    */
   markLoading: function (el) {
      let loader = el.retrieve('FormManager:loader');

      if (!loader) {
         loader = new Element('div').setStyles({
            width: 16,
            height: 16,
            position: 'absolute',
            backgroundImage:
               'url(' + window.shared_constants.BaseURI('SITE_IMAGE_LOADING_SMALL') + ')',
            backgroundRepeat: 'no-repeat',
         });
         el.store('FormManager:loader', loader);
      }

      let coords = el.getCoordinates();
      loader
         .setStyles({
            top: coords.top + 6, // Top padding of input fields
            left: coords.left + coords.width - 20, // Width of loader + 4
            zIndex: el.getEffectiveZindex(),
         })
         .inject(document.body);

      if (el.isFixed()) {
         loader.pin();
      }
   },

   /**
    * Removes the AJAX loading icon.
    */
   unmarkLoading: function (el) {
      let loader = el.retrieve('FormManager:loader');
      if (loader) {
         loader.dispose();
      }
   },

   /**
    * Clears the form of default values, so that they don't get submitted as
    * user input.
    */
   removeDefaultValues: function () {
      FormLibrary.getFields(this.form).each(el => {
         if (el.hasClass('defaultText')) {
            el.value = '';
            el.removeClass('defaultText');
         }
      });
   },

   /**
    * Checks each watched field in the form to see if its value has changed from
    * the original and stores that value in the field.
    */
   updateModifiedStatus: function () {
      let modified = this.watchFields.some(field => field.retrieve('FormManager:modified', false));

      if (modified != this.modified) {
         this.fireEvent('modifiedChanged', modified);
         this.modified = modified;
      }
   },
}));

/**
 * A wrapper for "this field is required" errors, so that FormManager can
 * distinguish them as a special case of normal errors.
 */
FormManager.RequiredError = function (msg) {
   this.message = msg;
};

/**
 * A specialized validator that validates via AJAX, blocking submission until
 * validation is done.
 */
FormManager.AjaxIOValidator = new Class({
   Implements: [Events],

   initialize: function (validator, processor, options) {
      this.processor = processor;
      this.options = Object.merge(
         {
            onSuccess: this.onSuccess.bind(this),
         },
         options
      );

      this.request = new Request.AjaxIO(validator, this.options);
   },

   validate: function () {
      let args = Array.convert(arguments);
      this.request.send.apply(this.request, args);
      return this;
   },

   onSuccess: function (response) {
      let result = this.processor(response);
      this.fireEvent('onComplete', [result]);
   },
});

/**
 * A signal validator that just indicates to the form manager that the
 * validator will take care of displaying and hiding the error itself.
 */
FormManager.CustomError = function () {};

// Info Tips
tippy.setDefaultProps({
   delay: [50, 150],
   interactive: true,
});

tippy('.info-tip', {
   content: reference => {
      const contentElement = reference.closest('.info-tip').querySelector('[data-tippy-content]');

      return contentElement ? contentElement.innerHTML : 'Unable to load tooltip content';
   },
   allowHTML: true,
   placement: 'auto-end',
   theme: 'info-tip',
});

/**
 * Class: StatusFormManager
 *
 * A simple subclass of FormManager that passes along a "status" string to the
 * callback it is instantiated with. The status indicates whether the input is:
 *  - focused                 ("focused")
 *  - has a validation error  ("error")
 *  - has no errors           ("ok")
 */
// eslint-disable-next-line no-var
export var StatusFormManager = (window.StatusFormManager = new Class({
   Extends: FormManager,

   initialize: function (formEl, options) {
      formEl = $(formEl);
      this.parent(formEl, options);

      this.statusChanged = options.statusChanged || function () {};
   },

   focused: function (inputEl) {
      let hasError;
      this.parent(inputEl);

      this.statusChanged(inputEl, 'focused');
   },

   blurred: function (inputEl) {
      let error;
      this.parent(inputEl);

      error = inputEl.retrieve('FormManager:hasError');
      this.statusChanged(inputEl, error ? 'error' : 'ok');
   },
}));
